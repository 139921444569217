<div style="heigth: 500px" class="y3-Small y3-flex-column">

  <table class="y3-full-width">
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.corporateOwner">Corporate Owners</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.onBoarded">Onboarded</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.nonOnBoarded">Non Onboarded</mat-checkbox></td>


      <td><mat-checkbox [(ngModel)]="filterPerson.inTrial">In Trial</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.inTrialOver">In Trial Over</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.premium">Premium</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.performance">Performance</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.paidCustomer">Customer (Payment Method)</mat-checkbox></td>
      </tr>
  <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.closedAccount">Closed Account</mat-checkbox></td>
    </tr>

    <tr >
      <td><mat-checkbox [(ngModel)]="filterPerson.hasSmily">Smily</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasAvantio">Avantio</mat-checkbox></td>
    </tr>
  <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasBeds24">Beds24</mat-checkbox></td>

      <td><mat-checkbox [(ngModel)]="filterPerson.hasRentalReady">RentalReady</mat-checkbox></td>
  </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasEviivo">Eviivo</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasSmoobu">Smoobu</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasSepteo">Septeo</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasGiteDeFrance">GiteDeFrance</mat-checkbox></td>
      </tr>

    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasIcnea">Icnea</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasHostify">Hostify</mat-checkbox></td>
    </tr>
  <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasBooking">Booking</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasAirbnb">Airbnb</mat-checkbox></td>
  </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasSuperhote">Superhote</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasClf">Réseau Clf</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasLuckey">Partner Luckey</mat-checkbox></td>
    </tr>

    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasIgloohome">IglooHome</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasIglooCo">Igloo Connect</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasThekeys">TheKeys</mat-checkbox></td>
    </tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasNuki">Nuki</mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="filterPerson.hasMinut">Minut</mat-checkbox></td>
    </tr>
  </table>







  <div class="y3-flex-row y3-flex-justify-content-end">
    <div class="m2-button y3-clickable" (click)="save()">Save</div>
  </div>
</div>

