<div class="y3-margin-top-24 y3-padding-left-16">

  <div class="y3-flex-row y3-flex-justify-content-center">
    <div class="y3-clickable m2-button y3-margin-bottom-16" (click)="purge(); refresh()">REFRESH</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-subscription-ok">MRR {{paidUsers.mrr | currency: 'EUR'}}</div>
    <div class="m0-subscription-ok">PREMIUM {{paidUsers.mrrPremium | currency: 'EUR'}}</div>
    <div class="m0-subscription-ok">PERFORMANCE {{paidUsers.mrrPerformance | currency: 'EUR'}}</div>
    <div class="m0-subscription-ok">SUCCESS {{paidUsers.mrrSuccess | currency: 'EUR'}}</div>
    <div class="m0-subscription-ok">MSGS {{paidUsers.mrrMessage | currency: 'EUR'}}</div>
    <div class="m0-trial-over">DUE {{funFactResult.totalAmountDue | currency: 'EUR'}}</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-beta">{{paidUsers.numberOfPaidUsers | number}} PAID USERS</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidUsersPremium | number}} PREMIUM USERS</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidUsersPerformance | number}} PERFORMANCE USERS</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidUsersSuccess | number}} SUCCESS USERS</div>
    <div class="m0-subscription-ok">{{funFactResult.numberOfActiveUsers | number}} ACTIVE USERS</div>
    <div class="m0-subscription-ok">{{funFactResult.numberOfActiveMobileUsers | number}} ACTIVE YAAGOTEAM</div>
    <div class="m0-trial">{{paidUsers.numberOfTrialUsers | number}} TRIAL </div>
    <div class="m0-trial-over">{{paidUsers.numberOfTrialOverUsers | number}} TRIAL OVER</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-beta">{{paidUsers.numberOfPaidProperties | number}} PROPERTIES</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidPropertiesPremium | number}} PREMIUM</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidPropertiesPerformance | number}} PERFORMANCE</div>
    <div class="m0-beta">{{paidUsers.numberOfPaidPropertiesSuccess | number}} SUCCESS</div>
    <div class="m0-trial">{{paidUsers.numberOfTrialProperties | number}} TRIAL</div>
    <div class="m0-trial-over">{{paidUsers.numberOfPausedProperties | number}} PAUSED</div>
    <div class="m0-trial-over">{{paidUsers.numberOfTrialOverProperties | number}} TRIAL OVER</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-onboarding">{{funFactResult.totalPropertyBookings | number}} BOOKINGS</div>
    <div class="m0-onboarding">{{funFactResult.nbBookletOpenned  | number}} BOOKLETS</div>
    <div class="m0-onboarding">{{funFactResult.nbOnlineCheck  | number}} ONLINE CHECK</div>
    <div class="m0-onboarding">{{funFactResult.totalUpsellsProducts  | number}} UPSELLS</div>
    <div class="m0-onboarding">{{funFactResult.totalGuides  | number}} GUIDES</div>
    <div class="m0-onboarding">{{funFactResult.guestCountries | number}} COUNTRIES</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-onboarding">{{funFactResult.nbSmsMsgIn | number}} SMS IN</div>
    <div class="m0-onboarding">{{funFactResult.nbSmsMsgOut | number}} SMS OUT</div>
    <div class="m0-onboarding">{{funFactResult.nbWhatsAppMsgIn | number}} WHATSAPP IN</div>
    <div class="m0-onboarding">{{funFactResult.nbWhatsAppMsgOut | number}} WHATSAPP OUT</div>
    <div class="m0-onboarding">{{funFactResult.nbDirectMsgIn | number}} DIRECT OUT</div>
    <div class="m0-onboarding">{{funFactResult.nbDirectMsgOut | number}} DIRECT OUT</div>
  </div>

  <div class="y3-flex-row-wrap m3-container" *ngIf="funFactResult && paidUsers">
    <div class="m0-subscription-ok">{{funFactResult.nbRecommendations | number}} RECO</div>
    <div class="m0-subscription-ok">{{funFactResult.nbYaagoRecommendation | number}} RECO YAAGO</div>
  </div>

</div>
