<div class="y3-margin-top-20"></div>
<div *ngIf="showLoading">
  <div class="m0-loader" style="margin-top: 24px;" *ngIf="showLoading">
    <img  src="../assets/loading.svg" alt="picto" class="margin-top-24 m0-rotate-loader" >
  </div>
</div>
<div *ngIf="!showLoading" (keypress)="checkKeyPress($event)">

  <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center " >
    <div style="position: relative; flex: 1; margin-right: 24px; width: calc(50% - 64px)">
      <img style="position: absolute; left: 16px; top: 10px;" class="y3-search-icon y3-clickable" src="assets/icons/search.svg" alt="search" (click)="this.pagination.pageNumber = 0; refreshProcedure()">
      <img style="position: absolute; right: 16px; top: 8px;" class="y3-search-icon y3-clickable" src="assets/icons/filter.svg" alt="filters" (click)="toggleFilters()">
      <input class="y3-search-text y3-padding-right-32 y3-padding-left-32 m0-search-bar"  [(ngModel)]="filterPerson.personSearch" type="text">

    </div>

    <div class=" y3-flex-row y3-flex-align-center">


      <div class="flex-column">
        <div  *ngIf="!(pagination.first && pagination.last)" class="y3-margin-right-8 y3-X-small">{{pagination.pageNumber+1}} / {{pagination.totalPages}} Page(s)</div>
        <div class="y3-X-small"> {{pagination.totalElements}} Person(s)</div>
      </div>
      <img  *ngIf="!(pagination.first && pagination.last)" (click)="previousPage()" class="m0-logo-nav y3-clickable" src="{{pagination.first?'assets/icons/left-chevron-gray.png' : 'assets/icons/left-chevron.png'}}">
      <img  *ngIf="!(pagination.first && pagination.last)" (click)="nextPage()" class="m0-logo-nav y3-clickable" src="{{pagination.last?'assets/icons/right-chevron-gray.png' : 'assets/icons/right-chevron.png'}}">
    </div>
  </div>

  <div class="y3-margin-top-8">
    <div *ngFor="let person of filteredUsers; let i = index" style="display: flex; flex-flow: column; margin-left: 8px; margin-right: 8px" class="y3-margin-bottom-2 m0-container-person">
      <div class="y3-flex-responsive ">
        <div class="y3-clickable y3-margin-bottom-8 y3-flex-row y3-flex-justify-content-center mo-picture-container" (click)="copyClipBoard(person)">
          <div *ngIf="!person.pictureUrl && !person.logoUrl" class="m0-avatar-letter y3-margin-right-8"> {{utilService.getFirstLetterString(person.firstName, person.lastName, person.email)}} </div>
          <img class="m0-avatar-picture y3-margin-right-8" *ngIf="person?.pictureUrl" src="{{person?.pictureUrl}}" alt="picture">
          <img class="m0-avatar-picture y3-margin-right-8" *ngIf="person?.logoUrl && !person.pictureUrl" src="{{person?.logoUrl}}" alt="picture">
        </div>
        <div class="y3-flex-row y3-flex-justify-content-center mo-picture-container y3-margin-bottom-8">
          <div class="m0-empty" *ngIf="!person.corporateOwner">&nbsp;</div>
          <div class="m0-closed" *ngIf="person.corporateOwner && person.closed">CLOSED</div>
          <div *ngIf="person.corporateOwner && !person.closed">
            <div class="m0-beta" *ngIf="person.beta">BETA</div>
            <div class="m0-subscription-ok" *ngIf="!person.beta &&  !isTrial(person) && !isTrialOver(person)">{{person.subscriptionName}}</div>
            <div class="m0-trial" *ngIf="!person.beta && isTrial(person)">TRIAL - {{utilService.difDays(person.trialPeriodOverDate) + 1}} Days left</div>
            <div class="m0-trial-over" *ngIf="!person.beta && isTrialOver(person)">TRIAL OVER</div>

            <div class="m0-onboarding y3-margin-top-4" *ngIf="!person?.onBoarding?.onBoarded">OnBoarding ...</div>
            <div class="m0-subscription-ok y3-margin-top-4" *ngIf=" isTrial(person) && person?.onBoarding?.onBoarded">Onboarded</div>
          </div>

        </div>
        <div class="y3-flex-column y3-margin-right-16">
          <div class="y3-flex-row y3-flex-align-center">
            <div *ngIf="person.corporateOwner" class="y3-clickable y3-bold" style="font-weight: bold; font-size: 16px; color: #03224C" >{{person.firstName}} {{person.lastName}}<sup *ngIf="person.corporateOwner">*</sup></div>
            <div *ngIf="!person.corporateOwner" class="y3-normal">{{person.firstName}} {{person.lastName}}</div>
            <img class="y3-clickable y3-margin-left-8" style="height: 20px;" src="../../../assets/icons/share.png" routerLink="{{'/user-details/' + person.corporateId}}">
          </div>

          <div class="y3-bold" style="font-size: 14px; color: #03224C; text-decoration: underline " >{{person.corporateName}}</div>

          <div class="y3-flex-row y3-flex-align-center">
            <div class="y3-X-small">{{person.email}}</div>
            <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoard(person)">
          </div>
          <div class="y3-X-small">{{person.phone}}&nbsp;</div>
          <div class="y3-X-small">{{person.country}}&nbsp;</div>
          <div class="y3-X-small">{{person.language}}&nbsp;</div>

          <div class="y3-X-small"><strong>Creation Date :</strong> {{utilService.formatDateTime(person.creationTime)}} - {{utilService.difDays(person.creationTime)}} Days</div>
          <div class="y3-X-small"><strong>Lastview Date :</strong> {{utilService.formatDateTime(person.lastTimeViewed)}} - {{utilService.difDays(person.lastTimeViewed)}} Days</div>
          <div class="y3-X-small" style="color: indianred" *ngIf="!person.emailVerified">Email unVerified</div>
        </div>
        <div class="y3-flex-column">

          <div class="y3-X-small" *ngIf="person?.stripePromotionCode"><strong>Promo code : </strong>{{person.stripePromotionCode}}</div>
          <div class="y3-X-small" *ngIf="person?.stripeMinimumPropertiesNumber > 0"><strong>Minimum : </strong>{{person.stripeMinimumPropertiesNumber}}</div>
          <div class="y3-X-small" *ngIf="person?.onBoarding?.howDoYouKnowUs"><strong>Know us : </strong>{{person.onBoarding.howDoYouKnowUs}}</div>
          <div class="y3-X-small" *ngIf="!person?.onBoarding?.onBoarded && person?.onBoarding?.currentStepName"><strong>Current step : </strong>{{person?.onBoarding?.currentStepName}}</div>
          <div class="y3-X-small" *ngIf="!person?.onBoarding?.onBoarded && person?.onBoarding?.numberOfProperties"><strong>Declared properties : </strong>{{person?.onBoarding?.numberOfProperties}}</div>

          <div class="y3-X-small y3-margin-top-8" style="font-weight: bold; color: #03224C;  ">
            <span class="y3-margin-right-4 m0-paiment" *ngIf="person.hasPaymentMethod">Paiment Method</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.airbnbAccountIds?.length > 0">Airbnb</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.bookingDotComAccountIds?.length > 0">Booking</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.bookingSynchAccountIds?.length > 0">Smily</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.beds24AccountIds?.length > 0">Beds24</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.smoobuAccountIds?.length > 0">Smoobu</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.avantioAccountIds?.length > 0">Avantio</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.eviivoAccountIds?.length > 0">Eviivo</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.superhoteAccountIds?.length > 0">Superhote</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.icneaAccountIds?.length > 0">Icnea</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.hostifyAccountIds?.length > 0">Hostify</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.octorateAccountIds?.length > 0">Octorate</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.rentalreadyAccountIds?.length > 0">RentalReady</span>
            <span class="y3-margin-right-4 m0-ota" *ngIf="person.guestyAccountIds?.length > 0">Guesty</span>

            <span class="y3-margin-right-4 m0-connected" *ngIf="person.numberOfNuki > 0">{{person.numberOfNuki}} Nuki</span>
            <span class="y3-margin-right-4 m0-connected" *ngIf="person.numberOfIglooCo > 0">{{person.numberOfIglooCo}} IglooCo</span>
            <span class="y3-margin-right-4 m0-connected" *ngIf="person.numberOfIgloohome > 0">{{person.numberOfIgloohome}} IglooHome</span>
            <span class="y3-margin-right-4 m0-connected" *ngIf="person.numberOfTheKeys > 0">{{person.numberOfTheKeys}} TheKeys</span>
            <span class="y3-margin-right-4 m0-connected" *ngIf="person.numberOfMinut > 0">{{person.numberOfMinut}} Minut</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

