import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import { ConfService } from './conf.service';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { PersonDto } from '../../model/PersonDto';
import {PersonFilter} from "../../model/PersonFilter";
import {TrialPeriodUpdate} from "../../model/TrialPeriodUpdate";
import {MinimumPropertiesUpdate} from "../../model/MinimumPropertiesUpdate";
import {Person} from "../../model/person";
import {PromotionCodeUpdate} from "../../model/PromotionCodeUpdate";
import {IndividualCompanyUpdate} from "../../model/IndividualCompanyUpdate";
import {Corporate} from "../../model/Corporate";



@Injectable({
  providedIn: 'root'
})

export class UsersService {

    constructor(private http: HttpClient,
                private confService: ConfService,
                private httpService: HttpService
    ) {}

    public personSearch(person: PersonFilter): Observable<PersonDto> {
      return this.http.post<PersonDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard/person/search-admin' , person , this.httpService.getTokenHeaders())
        .pipe(catchError(this.httpService.formatErrors));
    }

    public corporateSearch(corporateId: string): Observable<any> {
      return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate/' +  corporateId , this.httpService.getTokenHeaders())
        .pipe(catchError(this.httpService.formatErrors));
    }


  public forceAirbnbAccount(airbnbAccountEmail: string, corporateOwnerEmail: string): Observable<any> {
    return this.http.get<any>('https://app.yaago.com/api-v2/admin/airbnb/synchroAccount?airbnbEmail=' + airbnbAccountEmail + "&personEmail=" + corporateOwnerEmail , this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBeds24Account(apiKey: string): Observable<Person> {
    return this.http.get<Person>('https://app.yaago.com/api-v2/admin/beds24/yaago/synchroAccount?beds24ApiKey=' + apiKey, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceSmoobuAccount(apiKey: string): Observable<Person> {
    return this.http.get<Person>('https://app.yaago.com/api-v2/admin/smoobu/synchronize-account?smoobuApiKey=' + apiKey, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBookingDotComAccount(corporateOwnerEmail: string): Observable<any> {
    return this.http.get<any>('https://app.yaago.com/api-v2/admin/bookingdotcom/synchro?hostEmail=' + corporateOwnerEmail, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceRefreshGiteDeFrance(): Observable<any> {
    return this.http.get<any>('https://app.yaago.com/api-v2/admin/api-v2/test/gite-de-france/synchronizeAllAccount', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateTrialPeriod(trailPeriodUpdate: TrialPeriodUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/trial-period', trailPeriodUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateAirbnbSwikly(allowAirbnbSwikly: boolean, corporateId: string): Observable<Corporate> {
    return this.http.post<Corporate>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/swikly-airbnb?swiklyAirbnb=' + allowAirbnbSwikly + "&corporateId=" + corporateId, null, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceEmailVerification(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-verification', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forcePremium(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-premium', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forcePerformance(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-performance', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceUnPaid(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-unpaid', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public removeUnpaid(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/remove-unpaid', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }


  public updateMinimumProperties(minimumPropertiesUpdate: MinimumPropertiesUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/minimum-properties', minimumPropertiesUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updatePromotionCode(promotionCodeUpdate: PromotionCodeUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/promotion-code', promotionCodeUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBookingSyncBufferByReference(bookingSyncReference: string): Observable<string> {
    return this.http.get<string>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/test/bookingsync-service/force-by-reference?reference=' + bookingSyncReference, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateCorporateIndividualCompany(individualCompanyUpdate: IndividualCompanyUpdate): Observable<Corporate> {
    return this.http.post<Corporate>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/individual-company', individualCompanyUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

}


