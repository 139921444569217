
<div class="toolbar" role="banner" *ngIf="checker">
  <img width="40" alt="Angular Logo" src="../assets/yaago.png" />
  <div class="spacer"></div>
  <app-navbar *ngIf="!viewKey"></app-navbar>
</div>

<div style="margin-top: 40px" *ngIf="viewKey">
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    "
  >
    <input
      class="input"
      type="text"
      name="myKey"
      id="myKey"
      placeholder="Enter your Master Key"
      [(ngModel)]="myKey"
    />
    <div class="card-view-key card-small" (click)="enterKey()" tabindex="0">
      <span>ENTER</span>
    </div>
  </div>

<div style="
margin-top: 50px;
display: flex;
justify-content: center;">
  <span class="switch">
    <input type="checkbox" id="switcher"
     [(ngModel)]="checker" >
    <label for="switcher"></label>
  </span>
</div>

</div>


<router-outlet *ngIf="!viewKey"></router-outlet>

