import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./conf.service";
import * as i3 from "./http.service";
export class UsersService {
    constructor(http, confService, httpService) {
        this.http = http;
        this.confService = confService;
        this.httpService = httpService;
    }
    personSearch(person) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard/person/search-admin', person, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    corporateSearch(corporateId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate/' + corporateId, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceAirbnbAccount(airbnbAccountEmail, corporateOwnerEmail) {
        return this.http.get('https://app.yaago.com/api-v2/admin/airbnb/synchroAccount?airbnbEmail=' + airbnbAccountEmail + "&personEmail=" + corporateOwnerEmail, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceBeds24Account(apiKey) {
        return this.http.get('https://app.yaago.com/api-v2/admin/beds24/yaago/synchroAccount?beds24ApiKey=' + apiKey, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceSmoobuAccount(apiKey) {
        return this.http.get('https://app.yaago.com/api-v2/admin/smoobu/synchronize-account?smoobuApiKey=' + apiKey, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceBookingDotComAccount(corporateOwnerEmail) {
        return this.http.get('https://app.yaago.com/api-v2/admin/bookingdotcom/synchro?hostEmail=' + corporateOwnerEmail, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceRefreshGiteDeFrance() {
        return this.http.get('https://app.yaago.com/api-v2/admin/api-v2/test/gite-de-france/synchronizeAllAccount', this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    updateTrialPeriod(trailPeriodUpdate) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/trial-period', trailPeriodUpdate, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    updateAirbnbSwikly(allowAirbnbSwikly, corporateId) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/swikly-airbnb?swiklyAirbnb=' + allowAirbnbSwikly + "&corporateId=" + corporateId, null, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceEmailVerification(emailPerson) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-verification', emailPerson, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forcePremium(emailPerson) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-premium', emailPerson, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forcePerformance(emailPerson) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-performance', emailPerson, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceUnPaid(emailPerson) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-unpaid', emailPerson, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    removeUnpaid(emailPerson) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/remove-unpaid', emailPerson, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    updateMinimumProperties(minimumPropertiesUpdate) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/minimum-properties', minimumPropertiesUpdate, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    updatePromotionCode(promotionCodeUpdate) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/promotion-code', promotionCodeUpdate, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    forceBookingSyncBufferByReference(bookingSyncReference) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/test/bookingsync-service/force-by-reference?reference=' + bookingSyncReference, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
    updateCorporateIndividualCompany(individualCompanyUpdate) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/individual-company', individualCompanyUpdate, this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService), i0.ɵɵinject(i3.HttpService)); }, token: UsersService, providedIn: "root" });
