import {OnBoarding, Person} from './person';
import {ActionSetup, OtaListing} from './property';
import {BookingDotComAccount} from "./BookingDotComAccount";
import {AirbnbAccount} from "./AirbnbAccount";
import {CohostInvitation, TeammateInvitation} from "./AbsEmailEvent";
import {AbstractOtaAccount} from "./AbstractOtaAccount";
import {Corporate} from "./Corporate";


export class PersonDto {
  content: PersonDtoContent[];
  totalElements: number;
  number: number;
}

export class PersonDtoOld extends Person {
  // properties: PropertyInfoDto[];

  corporate: Corporate;
  corporateOwner: boolean;
  commentCount: number;
  pausedProperties: number;
  asMainHostCount: number;
  asCohostCount: number;
  asTeammateCount: number;
  emailVerified: boolean;

  airbnbAccounts: AirbnbAccount[];
  bookingDotComAccounts: BookingDotComAccount[];
  avantioAccounts: AvantioAccount[];
  smoobuAccounts: SmoobuAccount[];
  bookingSynchAccounts: BookingSyncAccount[];
  beds24Accounts: Beds24Account[];
  eviivoAccounts: EviivoAccount[];
  giteDeFranceAccounts: GiteDeFranceAccount[];

  septeoAccounts: SepteoAccount[];

  septeoAgencyIds: string[];

  emailVerification: RegisterVerification;

  whoInvitMeAsTeammate: WhoInvite;
  whoInvitMeAsCoHost: WhoInvite;

  asCohostInvitations: CohostInvitation[];
  asTeammateInvitations: TeammateInvitation[];

  igloohomeNumberOfLocks: number;
  theKeysNumberOfLocks: number;
  influencerDetails: InfluencerDetails;
  closed: boolean;
  corporatesRoles: RoleCorporate[];
  pushNotificationDeviceTokens: string[];

  registrationHowDoYouKnowUs: string;
  registrationOtherTools: string;
  registrationRentalsNumber: string;


}

export class RoleCorporate {
  corporateId: string;
  roleId: string;
}

export class InfluencerDetails {
  bio: string;
  socialMediaUrl: string;
}
export class WhoInvite {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  status: string;
  sendingTime: string;
}

export class RegisterVerification {
  status: string;
  lastEmailType: string;
  statusTime: string;
}
export class  PropertyInfoDto {
  id: string;
  title: string;
  airbnbId: number;
  calendartypes: string[];
  guideCount: number;
  usefulNumberCount: number;
  forceMajeureItemCount: number;
  fullAddress: string;
  hostCount: number;
  teammateCount: number;
  actions: ActionSetup[];
  otaListings: OtaListing[];
  creationDate: string;
}
export class AvantioAccount  extends AbstractOtaAccount{
  avantioEmail: string;
  avantioPassword: string;
  avantioRootUrl: string;
  avantioHandleCorporate: boolean;
  avantioLayoutType: string;
  avantioCredentialUsername: string;
  avantioCredentialPassword: string;
  avantioProviderId: string;
}

export class BookingSyncAccount extends AbstractOtaAccount {
  username: string;
  businessName: string;
  email: string;
  bookingSyncId: number;
  lastAccessRefresh: string;
  refreshToken: string;
  expires_in: number;
}

export class Beds24Account extends AbstractOtaAccount {
  username: string;
  apiKey: string;
}

export class EviivoAccount extends AbstractOtaAccount {
  clientId: string;
  clientSecret: string;
}

export class GiteDeFranceAccount extends  AbstractOtaAccount {
  giteDeFranceEmail: string;
  giteDeFranceChannelId: string;
}

export class SepteoAccount extends AbstractOtaAccount {
  user: string;
}

export class SmoobuAccount extends AbstractOtaAccount {
  firstName: string;
  lastName: string;
  email: string;
  apiKey: string;
  userd: string;
}

export class PersonDtoContent {

  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pictureUrl: string
  country: string;
  emailVerified: boolean;
  closed: boolean;
  language: string;

  creationTime: string;
  lastTimeViewed: string;

  corporateId: string;
  corporateOwner: boolean;

  corporateName: string;
  logoUrl: string;
  roleName: string;

  trialPeriodOver: boolean;
  trialPeriodOverDate: string;

  beta: boolean;
  hasPaymentMethod: boolean;

  stripePromotionCode: string;
  stripeTaxId: string;

  onBoarding: OnBoarding;
  stripeMinimumPropertiesNumber: number;
  subscriptionName: string;

  airbnbAccountIds: string[];
  bookingDotComAccountIds: string[];

  beds24AccountIds: string[];
  avantioAccountIds: string[];
  eviivoAccountIds: string[];
  superhoteAccountIds: string[];
  bookingSynchAccountIds: string[];
  rentalreadyAccountIds: string[];
  hostifyAccountIds: string[];
  octorateAccountIds: string[];
  icneaAccountIds: string[];
  guestyAccountIds: string[];

  smoobuAccountIds: string[];
  giteDeFranceAccountIds: string[];
  septeoAgencyIds: string[];

  numberOfNuki: number;
  numberOfIgloohome: number;
  numberOfTheKeys: number;
  numberOfMinut: number;
  numberOfIglooCo: number;

}
