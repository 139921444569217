<div class="y3-margin-top-12 m1-page-container" *ngIf="adminCorporateDetailsDto">

  <div class="y3-flex-row-wrap">

    <div class="m0-guest" *ngIf="adminCorporateDetailsDto.potentialVoyageur">POTENTIAL GUEST</div>


    <div class="m0-subscription-ok" *ngIf="!adminCorporateDetailsDto.corporateOwner.beta &&  !isTrial(adminCorporateDetailsDto.corporateOwner) && !isTrialOver(adminCorporateDetailsDto.corporateOwner)">{{adminCorporateDetailsDto.corporateOwner?.subscriptionName}}</div>
    <div class="m0-trial" *ngIf="!adminCorporateDetailsDto.corporateOwner.beta && isTrial(adminCorporateDetailsDto.corporateOwner)">TRIAL - {{utilService.difDays(adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate) + 1}} Days left</div>
    <div class="m0-trial-over" *ngIf="!adminCorporateDetailsDto.corporateOwner.beta && isTrialOver(adminCorporateDetailsDto.corporateOwner)">TRIAL OVER</div>

    <div class="m0-trial-over" *ngIf="!adminCorporateDetailsDto.corporateOwner.stripePromotionCode && !adminCorporateDetailsDto.corporateOwner.stripeCustomPricePlanId">No promo code</div>
    <div class="m0-trial-over" *ngIf="adminCorporateDetailsDto.corporateOwner.stripeCustomPricePlanId">Custom plan</div>
    <div class="m0-subscription-ok" *ngIf="adminCorporateDetailsDto.corporateOwner.stripePromotionCode">{{adminCorporateDetailsDto.corporateOwner.stripePromotionCode}}</div>

    <div class="m0-subscription-ok" *ngIf="adminCorporateDetailsDto.corporateOwner.hasPaymentMethod">PAIMENT METHOD OK</div>
    <div class="m0-trial-over" *ngIf="!adminCorporateDetailsDto.corporateOwner.hasPaymentMethod">NO PAIMENT METHOD</div>
    <div class="m0-subscription-ok" *ngIf="adminCorporateDetailsDto.corporateOwner.stripeTaxId">{{adminCorporateDetailsDto.corporateOwner.stripeTaxId}}</div>



    <div *ngIf="adminCorporateDetailsDto.corporateOwner?.totalPendingAmount?.amount > 0" class="m0-trial-over">{{adminCorporateDetailsDto.corporateOwner.totalPendingAmount.amount}}</div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner?.lastPaidAmount?.amount > 0" class="m0-trial">{{adminCorporateDetailsDto.corporateOwner.lastPaidAmount.amount | currency: adminCorporateDetailsDto.corporateOwner.lastPaidAmount.currency}}</div>
    <div *ngIf="adminCorporateDetailsDto.corporateOwner?.lastPaidAmountMessage?.amount > 0" class="m0-trial">{{adminCorporateDetailsDto.corporateOwner.lastPaidAmountMessage.amount | currency: adminCorporateDetailsDto.corporateOwner.lastPaidAmountMessage.currency}}</div>

    <div class="m0-onboarding " *ngIf="!adminCorporateDetailsDto.corporateOwner?.onBoarding?.onBoarded">OnBoarding ...</div>
    <div class="m0-subscription-ok " *ngIf=" isTrial(adminCorporateDetailsDto.corporateOwner) && adminCorporateDetailsDto.corporateOwner?.onBoarding?.onBoarded">Onboarded</div>



    <div class="m0-subscription-ok">{{adminCorporateDetailsDto.nbActiveProperties}} Properties</div>
    <div class="m0-trial-over">{{adminCorporateDetailsDto.nbInActiveProperties}} Inactive(s)</div>

  </div>

  <div class="y3-flex-row y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.potentialVoyageur">
    <div class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px; color: #fe3945">Booklet as Guest:</div>
    <div *ngFor="let url of adminCorporateDetailsDto.bookletUrls">
      <a style="color: #fe3945; text-decoration: none" target="_blank" [href]="url">{{url}}</a>
    </div>
  </div>


  <div class="y3-flex-row y3-flex-align-start y3-margin-top-16">
    <div class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Corporate:</div>
    <div>
      <div>

        <div>
          <div class="y3-Medium y3-bold">{{adminCorporateDetailsDto.corporate.name}}</div>
          <div class="y3-Small y3-normal">{{adminCorporateDetailsDto.corporate.address}} {{adminCorporateDetailsDto.corporate.zipCode}} {{adminCorporateDetailsDto.corporate.city}} {{adminCorporateDetailsDto.corporate.country}}</div>
          <div class="y3-X-small y3-normal">{{adminCorporateDetailsDto.corporate.id}}</div>
        </div>
      </div>


      <div class="y3-flex-row y3-flex-align-center y3-margin-top-4">
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.iglooHome?.locks?.length > 0">{{adminCorporateDetailsDto.corporate?.iglooHome?.locks?.length}} Igloohome</div>
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.theKeys?.locks?.length > 0">{{adminCorporateDetailsDto.corporate?.theKeys?.locks?.length}} TheKeys</div>
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.nukiInformation?.nukiSmartLocks?.length > 0">{{adminCorporateDetailsDto.corporate?.nukiInformation?.nukiSmartLocks?.length}} Nuki</div>
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.swiklyLinked">Swikly</div>
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.swiklyLinked && adminCorporateDetailsDto.corporate?.swiklyAirbnbAllowed">Swikly with Airbnb</div>
        <div class="y3-margin-right-4 m0-devices" *ngIf="adminCorporateDetailsDto.corporate?.stripeAccountId">Stripe</div>
      </div>

    </div>
  </div>




  <div class="y3-flex-responsive y3-flex-align-start y3-margin-top-16">
    <div class="y3-Medium y3-margin-right-16 y3-bold y3-underline y3-margin-bottom-8" style="width: 150px;">Corporate Owner:</div>
    <div class="y3-flex-row y3-flex-align-center">
      <div class="y3-clickable" (click)="copyClipBoard(adminCorporateDetailsDto.corporateOwner)">
        <div *ngIf="!adminCorporateDetailsDto.corporateOwner.pictureUrl && !adminCorporateDetailsDto.corporate.logoUrl" class="m0-avatar-letter y3-margin-right-8"> {{utilService.getFirstLetterString(adminCorporateDetailsDto.corporateOwner.firstName, adminCorporateDetailsDto.corporateOwner.lastName, adminCorporateDetailsDto.corporateOwner.email)}} </div>
        <img class="m0-avatar-picture y3-margin-right-8" *ngIf="adminCorporateDetailsDto.corporateOwner?.pictureUrl" src="{{adminCorporateDetailsDto.corporateOwner?.pictureUrl}}" alt="picture">
        <img class="m0-avatar-picture y3-margin-right-8" *ngIf="adminCorporateDetailsDto.corporate?.logoUrl && !adminCorporateDetailsDto.corporateOwner.pictureUrl" src="{{adminCorporateDetailsDto.corporate?.logoUrl}}" alt="picture">
      </div>
      <div>
        <div class="y3-Medium y3-bold">{{adminCorporateDetailsDto.corporateOwner.firstName}} {{adminCorporateDetailsDto.corporateOwner.lastName}}</div>
        <div class="y3-flex-row y3-flex-align-center">
          <div class="y3-Small y3-normal">{{adminCorporateDetailsDto.corporateOwner.email}}</div>
          <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoard(adminCorporateDetailsDto.corporateOwner)">
          <div *ngIf="adminCorporateDetailsDto.corporateOwner.pushNotificationDeviceTokens?.length > 0" class="y3-margin-left-8" style="color: #14A1DF">(Yaago Team)</div>
        </div>

        <div class="y3-Small y3-normal">{{adminCorporateDetailsDto.corporateOwner.phone}}</div>
        <div class="y3-Small y3-normal">{{adminCorporateDetailsDto.corporateOwner.country}}</div>
        <div class="y3-X-small">{{adminCorporateDetailsDto.corporateOwner.id}}</div>

        <div class="y3-X-small y3-normal"><strong>Language :</strong> {{adminCorporateDetailsDto.corporateOwner.language}}</div>
        <div class="y3-X-small" *ngIf="adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo?.appVersion" ><strong>AppVersion:</strong> {{adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo.appVersion}}</div>
        <div class="y3-X-small" *ngIf="adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo?.mobileType" ><strong>MobileType:</strong> {{adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo.mobileType}}</div>
        <div class="y3-X-small" *ngIf="adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo?.osVersion" ><strong>OsVersion:</strong> {{adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo.osVersion}}</div>
        <div class="y3-X-small" *ngIf="adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo?.appTimeZone" ><strong>TimeZone:</strong> {{adminCorporateDetailsDto.corporateOwner.yaagoTeamInfo.appTimeZone}}</div>

        <div class="y3-X-small"><strong>Creation Date :</strong> {{utilService.formatDateTime(adminCorporateDetailsDto.corporateOwner.creationTime)}} - {{utilService.difDays(adminCorporateDetailsDto.corporateOwner.creationTime)}} Days</div>
        <div class="y3-X-small"><strong>Lastview Date :</strong> {{utilService.formatDateTime(adminCorporateDetailsDto.corporateOwner.lastTimeViewed)}} - {{utilService.difDays(adminCorporateDetailsDto.corporateOwner.lastTimeViewed)}} Days</div>
      </div>

      <div class="y3-margin-top-8 y3-flex-row y3-flex-align-center" *ngIf="corporateId !== '65d9cd8a7d162e1b10e91aa3'">
        <div  class="y3-clickable m1-button" (click)="propagateNormal()">
          PROPAGATE &nbsp; <strong> NORMAL </strong> &nbsp; TEMPLATES
        </div>
      </div>

    </div>
  </div>

  <div class="m1-accordion" (click)="changeStep('MAKECHANGE')">
    MAKE CHANGES
  </div>

  <div class="y3-margin-bottom-16" *ngIf="currentStep === 'MAKECHANGE'">
    <div class="y3-Medium y3-margin-top-16 y3-bold y3-underline" style="width: 150px;">Make changes :</div>

    <div class="y3-margin-top-8 y3-flex-row y3-flex-align-center">
      <div class="y3-margin-right-4">PromotionCode : </div>
      <input class="y3-input-text-small y3-margin-right-4" [(ngModel)]="localPromotionCode"/>
      <div  class="y3-clickable m2-button" (click)="validatePromotionCode()">
        VALIDATE
      </div>
    </div>
    <div class="y3-margin-top-8 y3-flex-row y3-flex-align-center">
      <div class="y3-margin-right-4">Minimum Properties : </div>
      <input class="y3-input-text-small y3-margin-right-4" [type]="'number'" [(ngModel)]="localMinimumProperties"/>
      <div  class="y3-clickable m2-button" (click)="validateMinimum()">
        VALIDATE
      </div>
    </div>
    <div class="y3-margin-top-8 y3-flex-row y3-flex-align-center" >
      <div class="y3-margin-right-4">Trial Period Over Date : </div>
      <input class="y3-input-text-small y3-margin-right-4" [type]="'date'" [(ngModel)]="localTrialOverDate"/>
      <div  class="y3-clickable m2-button" (click)="validateTrialPeriod()">
        VALIDATE
      </div>
    </div>


    <div class="y3-margin-top-8 y3-flex-row-wrap y3-flex-align-center">
      <div *ngIf="!adminCorporateDetailsDto.corporateOwner.emailVerified" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4">
        FORCE EMAIL VERIFIED
      </div>
      <div *ngIf="adminCorporateDetailsDto.corporateOwner.beta" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="removeAsBeta(adminCorporateDetailsDto.corporateOwner)">
        REMOVE BETA
      </div>


      <div *ngIf="adminCorporateDetailsDto.corporate.swiklyAirbnbAllowed" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="manageAirbnbSwikly()">
        REMOVE AIRBNB SWIKLY
      </div>
      <div *ngIf="!adminCorporateDetailsDto.corporate.swiklyAirbnbAllowed" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="manageAirbnbSwikly()">
        ADD AIRBNB SWIKLY
      </div>

      <div *ngIf="!adminCorporateDetailsDto.corporateOwner.beta" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="setAsBeta(adminCorporateDetailsDto.corporateOwner)">
        MOVE TO BETA
      </div>
      <div *ngIf="!adminCorporateDetailsDto.corporateOwner.closed" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="closeUser(adminCorporateDetailsDto.corporateOwner)">
        CLOSE ACCOUNT
      </div>
      <div *ngIf="adminCorporateDetailsDto.corporateOwner.closed" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="uncloseUser(adminCorporateDetailsDto.corporateOwner)">
        UNCLOSE ACCOUNT
      </div>
      <div  class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="deleteUser(adminCorporateDetailsDto.corporateOwner)">
        DELETE ACCOUNT
      </div>
      <div *ngIf="!adminCorporateDetailsDto.corporateOwner?.emailVerified" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="validateEmailVerification()">
        FORCE VERIFIED EMAIL
      </div>

      <div *ngIf="adminCorporateDetailsDto.corporateOwner?.subscriptionName && adminCorporateDetailsDto.corporateOwner?.subscriptionName === 'premium'" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="forcePerformance()">
        FORCE PERFORMANCE
      </div>
      <div *ngIf="adminCorporateDetailsDto.corporateOwner?.subscriptionName && adminCorporateDetailsDto.corporateOwner?.subscriptionName.indexOf('performance', 0) >= 0" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="forcePremium()">
        FORCE PREMIUM
      </div>

      <div *ngIf="adminCorporateDetailsDto.corporateOwner?.failedInvoices == null || adminCorporateDetailsDto.corporateOwner?.failedInvoices?.length == 0" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="forceUnpaid()">
        FORCE UNPAID
      </div>
      <div *ngIf="adminCorporateDetailsDto.corporateOwner?.failedInvoices?.length > 0" class="y3-clickable m2-button y3-margin-right-4 y3-margin-bottom-4" (click)="removeUnpaid()">
        REMOVE UNPAID
      </div>

    </div>
  </div>


  <div class="m1-accordion" (click)="changeStep('STAFF')">
    {{adminCorporateDetailsDto.staffDetails?.length}} STAFF(S)
  </div>

  <div *ngIf="currentStep === 'STAFF'">
    <div *ngFor="let staff of adminCorporateDetailsDto.staffDetails" >
      <div class="y3-flex-align-center y3-flex-row" >
        <div class="y3-bold y3-margin-right-8" >{{staff.role}}</div>
        <div class="y3-margin-right-8">{{staff.firstName}} {{staff.lastName}}</div>
        <div class="y3-margin-right-8">{{staff.email}}</div>
        <div class="y3-X-small"><strong>Lastview Date :</strong> {{utilService.formatDateTime(staff.lastTimeViewed)}} - {{utilService.difDays(staff.lastTimeViewed)}} Days</div>

        <div *ngIf="staff.yaagoTeamTokens" class="y3-margin-left-8" style="color: #14A1DF">(Yaago Team)</div>
        <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoardStaff(staff)">
        <div *ngIf="staff.yaagoTeamInfo?.appVersion" >AppVersion: {{staff.yaagoTeamInfo.appVersion}}</div>
        <div *ngIf="staff.yaagoTeamInfo?.mobileType" >MobileType: {{staff.yaagoTeamInfo.mobileType}}</div>
        <div *ngIf="staff.yaagoTeamInfo?.osVersion" >OsVersion: {{staff.yaagoTeamInfo.osVersion}}</div>
        <div *ngIf="staff.yaagoTeamInfo?.appTimeZone" >TimeZone: {{staff.yaagoTeamInfo.appTimeZone}}</div>
      </div>
    </div>
  </div>


  <div class="m1-accordion" (click)="changeStep('OTACONNEXIONS')">
    OTA CONNEXIONS
  </div>
  <div *ngIf="currentStep === 'OTACONNEXIONS'">
    <div *ngIf="adminCorporateDetailsDto.corporateOwner.beds24Accounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Beds24:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.beds24Accounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.beds24Accounts">
        <div class="y3-X-small"><strong>ApiKey :</strong> {{ota.apiKey}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshBeds24(ota)">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.smoobuAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Smoobu:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.smoobuAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.smoobuAccounts">
        <div class="y3-X-small"><strong>ApiKey :</strong> {{ota.apiKey}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshSmoobu(ota)">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.superhoteAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Superhote:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.superhoteAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.superhoteAccounts">
        <div class="y3-X-small"><strong>Email :</strong> {{ota.email}}</div>
        <div class="y3-X-small"><strong>Password :</strong> {{ota.password}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshSmoobu(ota)">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.guestyAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Guesty:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.guestyAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.guestyAccounts">
        <div class="y3-X-small"><strong>Email :</strong> {{ota.clientId}}</div>
        <div class="y3-X-small"><strong>Password :</strong> {{ota.clientSecret}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshSmoobu(ota)">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.hostifyAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Hostify:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.hostifyAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.hostifyAccounts">
        <div class="y3-X-small"><strong>ApiKey :</strong> {{ota.apiKey}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshHostify(ota)">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.giteDeFranceAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">GiteDeFrance:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.giteDeFranceAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.giteDeFranceAccounts">
        <div class="y3-X-small"><strong>Email :</strong> {{ota.giteDeFranceEmail}}</div>
        <div class="y3-X-small"><strong>Password :</strong> {{ota.giteDeFrancePassword}}</div>
        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshGiteDeFrance()">
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.eviivoAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">eviivo:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.eviivoAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.eviivoAccounts">
        <div class="y3-X-small"><strong>ClientId :</strong> {{ota.clientId}}</div>
        <div class="y3-X-small"><strong>ClientSecret :</strong> {{ota.clientSecret}}</div>
        <div class="y3-X-small"><strong>LastAccessRefresh :</strong> {{utilService.formatDateTime(ota.lastAccessRefresh)}}</div>
        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button" >
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.rentalReadyAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">RentalReady:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.rentalReadyAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.rentalReadyAccounts">
        <div class="y3-X-small"><strong>ClientId :</strong> {{ota.rentalReadyUserId}}</div>
        <div class="y3-X-small"><strong>Username :</strong> {{ota.username}}</div>
        <div class="y3-X-small"><strong>LastAccessRefresh :</strong> {{utilService.formatDateTime(ota.lastAccessRefresh)}}</div>
        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div class="y3-X-small">{{ota.lastSyncElapseTime}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button" >
          FORCE REFRESH
        </div>
      </div>
    </div>


    <div *ngIf="adminCorporateDetailsDto.corporateOwner.septeoAgencyIds?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Septeo:</div>
    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.septeoAgencyIds?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.septeoAgencyIds">
        <div class="y3-X-small"><strong>AgencyId :</strong> {{ota}}
        </div>
        <div *ngIf="!isForceRefresh" class="y3-clickable y3-margin-top-8 m1-button" >
          FORCE REFRESH
        </div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.avantioAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Avantio:</div>

    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16"

         *ngIf="adminCorporateDetailsDto.corporateOwner.avantioAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.avantioAccounts">
        <div class="y3-X-small"><strong>Email:</strong> {{ota.avantioEmail}}</div>
        <div class="y3-X-small"><strong>Password:</strong> {{ota.avantioPassword}}</div>

        <div class="y3-X-small"><strong>ApiUser:</strong> {{ota.avantioCredentialUsername}}</div>
        <div class="y3-X-small"><strong>ApiPassword:</strong> {{ota.avantioCredentialPassword}}</div>
        <div class="y3-X-small"><strong>ProviderId:</strong> {{ota.avantioProviderId}}</div>
        <div class="y3-X-small"><strong>Nb Properties:</strong> {{ota.numberOfProperties}}</div>

        <!--<div class="y3-X-small"><strong>Layout:</strong> {{ota.avantioLayoutType}}</div>-->
        <div class="y3-X-small"><strong>Root url:</strong> {{ota.avantioRootUrl}}</div>
        <div class="y3-X-small"><strong>Months to load:</strong> {{ota.monthsToLoad}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div>{{ota.lastSyncElapseTime}}</div>
      </div>
    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.airbnbAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Airbnb:</div>

    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.airbnbAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.airbnbAccounts">
        <div class="y3-X-small y3-flex-row y3-flex-align-center"><strong>Identifiant Airbnb : </strong> {{ota.airbnbEmail}}
          <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoardText(ota.airbnbEmail)">
        </div>
        <div class="y3-X-small y3-flex-row y3-flex-align-center"><strong>Password :</strong> {{ota.airbnbPassword}}
          <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoardText(ota.airbnbPassword)">
        </div>
        <div class="y3-X-small"><strong>DeviceId :</strong> {{ota.airbnbDeviceId}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small"><strong>Just Update Existing Bookings :</strong> {{ota.justUpdateExistingBooking}}</div>

        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Last Elapse Time :</strong> {{ota.lastSyncElapseTime}}</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshAirbnbAccount(ota)">
          FORCE REFRESH
        </div>
        <div *ngIf="ota.otaTokenStatus !== 'VALID'" class="y3-clickable y3-margin-top-8 m2-button" (click)="removeOta('airbnb', ota.airbnbEmail)">
          DELETE ACCOUNT
        </div>
      </div>

    </div>


    <div *ngIf="adminCorporateDetailsDto.corporateOwner.bookingDotComAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Booking.com:</div>

    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.bookingDotComAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.bookingDotComAccounts">
        <div class="y3-X-small y3-flex-row y3-flex-align-center"><strong>Identifiant Pulse :</strong> {{ota.bookingDotComEmail}}
          <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoardText(ota.bookingDotComEmail)">
        </div>
        <div class="y3-X-small"><strong>Password :</strong> {{ota.bookingDotComPassword}}</div>
        <div class="y3-X-small"><strong>DeviceId :</strong> {{ota.bookingDotComDeviceId}}</div>
        <div class="y3-X-small"><strong>Nb Properties :</strong> {{ota.numberOfProperties}}</div>
        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Last Elapse Time :</strong> {{ota.lastSyncElapseTime}}</div>
        <div class="y3-X-small"><strong>ChallengeContext :</strong> {{ota.challengeContext}}</div>
        <div class="y3-X-small"><strong>ChallengeContextTime :</strong> {{ota.challengeContextTime}}</div>
        <div class="y3-X-small"><strong>ChallengeContextPhoneHash :</strong> {{ota.challengeContextPhoneHash}}</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button" (click)="forceRefreshBookingDotCom(adminCorporateDetailsDto.corporateOwner)">
          FORCE REFRESH
        </div>
        <div *ngIf="ota.otaTokenStatus !== 'VALID'" class="y3-clickable y3-margin-top-8 m2-button" (click)="removeOta('bookingdotcom', ota.bookingDotComEmail)">
          DELETE ACCOUNT
        </div>
      </div>

    </div>

    <div *ngIf="adminCorporateDetailsDto.corporateOwner.bookingSynchAccounts?.length > 0" class="y3-Medium y3-margin-right-16 y3-bold y3-underline" style="width: 150px;">Smily:</div>

    <div class="y3-flex-row-wrap y3-flex-align-start y3-margin-top-16" *ngIf="adminCorporateDetailsDto.corporateOwner.bookingSynchAccounts?.length > 0">
      <div class="y3-flex-column y3-margin-right-16 y3-margin-bottom-16 y3-padding-6"
           [ngStyle]="{'background-color' : (ota.otaTokenStatus === 'VALID') ? '#E5F6EB' : '#FCE9EA' }"
           *ngFor="let ota of adminCorporateDetailsDto.corporateOwner?.bookingSynchAccounts">
        <div class="y3-X-small  y3-flex-row y3-flex-align-center"><strong>Identifiant Smily :</strong> {{ota.email}}
          <img class="y3-clickable y3-margin-left-8" style="height: 25px;" src="../../../assets/icons/link.png" (click)="copyClipBoardText(ota.email)">
        </div>
        <div class="y3-X-small"><strong>BusinessName:</strong> {{ota.businessName}}</div>

        <div class="y3-X-small"><strong>lastSourceUpdateSince:</strong> {{utilService.formatDateTime(ota.lastSourceUpdateSince)}}</div>
        <div class="y3-X-small"><strong>lastClientUpdateSince:</strong> {{utilService.formatDateTime(ota.lastClientUpdateSince)}}</div>
        <div class="y3-X-small"><strong>lastBedroomsUpdateSince:</strong> {{utilService.formatDateTime(ota.lastBedroomsUpdateSince)}}</div>
        <div class="y3-X-small"><strong>lastConversationUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastConversationUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastMessageUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastMessageUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastBookingFeeUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastBookingFeeUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastPhotoUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastPhotoUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastReviewsUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastReviewsUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastTagUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastTagUpdatedSince)}}</div>

        <div class="y3-X-small"><strong>lastBookingTaxesUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastBookingTaxesUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastRentalUpatedSince:</strong> {{utilService.formatDateTime(ota.lastRentalUpatedSince)}}</div>
        <div class="y3-X-small"><strong>lastBookingUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastBookingUpdatedSince)}}</div>
        <div class="y3-X-small"><strong>lastHostsUpdatedSince:</strong> {{utilService.formatDateTime(ota.lastHostsUpdatedSince)}}</div>

        <div class="y3-X-small" [ngStyle]="{'color' : (ota.otaTokenStatus === 'VALID') ? 'black' : 'indianred' }"><strong>Status :</strong> {{ota.otaTokenStatus}}</div>
        <div class="y3-X-small"><strong>Last Sync Date :</strong> {{utilService.formatDateTime(ota.lastSyncDatetime)}} - {{utilService.difMinutes(ota.lastSyncDatetime)}} Mns</div>
        <div class="y3-X-small"><strong>Last Elapse Time :</strong> {{ota.lastSyncElapseTime}}</div>
        <div class="y3-X-small"><strong>Creation Time :</strong> {{utilService.formatDateTime(ota.creationDatetime)}}</div>
        <div *ngIf="!isForceRefresh && ota.otaTokenStatus === 'VALID'" class="y3-clickable y3-margin-top-8 m1-button">
          FORCE REFRESH
        </div>

        <div class="y3-margin-top-8 y3-bold y3-underline">Refresh a booking by its Reference like 0AUB9D</div>
        <div class="y3-flex-row y3-margin-top-8 y3-full-width">
          <input class="y3-input-text-small y3-margin-right-4" [(ngModel)]="bookingsynchreference"/>
          <div  class="y3-clickable m2-button" (click)="forcebookingSyncByReference()">
            VALIDATE
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
