import { Component, OnInit } from '@angular/core';
import {UsersService} from "../../core/services/users.service";
import {PersonFilter} from "../../model/PersonFilter";
import {PersonDtoContent} from "../../model/PersonDto";
import {Pagination} from "../../model/Pagination";
import {UtilService} from "../../core/services/UtilService";
import {MatDialog} from "@angular/material/dialog";
import {M01UsersFiltersComponent} from "./m01-users-filters/m01-users-filters.component";

@Component({
  selector: 'app-m0-users-list',
  templateUrl: './m0-users-list.component.html',
  styleUrls: ['./m0-users-list.component.scss']
})
export class M0UsersListComponent implements OnInit {

  filteredUsers: PersonDtoContent[] = [];
  filterPerson: PersonFilter = new PersonFilter()
  showLoading = false;
  pagination = new Pagination();
  constructor(private userService: UsersService,
              public utilService: UtilService,
              protected dialog: MatDialog) {

  }

  ngOnInit() {
    const filterPersonStorage = localStorage.getItem("currentPersonFilter");
    if (filterPersonStorage != null) {
      this.filterPerson = JSON.parse(filterPersonStorage);
    }
    this.refreshProcedure();
  }

  isTrial(user: PersonDtoContent) {
    if (user.hasPaymentMethod) {
      return false;
    }
    if (!user.trialPeriodOver) {
      return true;
    }
  }
  isTrialOver(user: PersonDtoContent) {
    if(user.trialPeriodOver && !user.hasPaymentMethod) {
      return true;
    }
    return false;
  }

  copyClipBoard(person: PersonDtoContent) {
    navigator.clipboard.writeText(person.email);
  }



  refreshProcedure() {
    localStorage.setItem("currentPersonFilter", JSON.stringify(this.filterPerson));
    this.showLoading = false;

    if (this.filterPerson.hasLuckey) {
      this.filterPerson.stripePromotionCode = "LUCKEY";
    } else {
      this.filterPerson.stripePromotionCode = "";
    }

    this.filterPerson.pageSize = 25;

    this.filterPerson.pageNumber = this.pagination.pageNumber;
    this.userService.personSearch(this.filterPerson).subscribe( res => {
      this.filteredUsers = res.content;
      this.pagination.totalElements = res.totalElements;
      this.pagination.currentCount = res.content.length;
      this.pagination.totalPages = Math.ceil(res.totalElements / this.pagination.pageSize);
      if (this.pagination.totalPages == 0) {
        this.pagination.totalPages = 1;
      }
      this.showLoading = false;
    });

  }

  previousPage() {
    if (this.pagination.pageNumber>=1) {
      this.pagination.pageNumber--;
      this.pagination.last = (this.pagination.pageNumber == this.pagination.totalPages);
      this.pagination.first = (this.pagination.pageNumber == 0);
      this.refreshProcedure();
    }
  }
  nextPage() {
    if (this.pagination.pageNumber < this.pagination.totalPages) {
      this.pagination.pageNumber++;
      this.pagination.last = (this.pagination.pageNumber == this.pagination.totalPages);
      this.pagination.first = (this.pagination.pageNumber == 0);
      this.refreshProcedure();
    }
  }

  toggleFilters() {
    const filterDialog = this.dialog.open(M01UsersFiltersComponent, {
      // height: this.isDesktopMode() ? '90%' : '100vh',
      width: '100vw',
      maxWidth: '1140px',
      position:  {
        top: '50px;',
      },
      autoFocus: false,
      data: {
        filterPerson: this.filterPerson
      }
    });

    filterDialog.afterClosed().subscribe(result => {
      if (result != null) {
        this.filterPerson = result;
        if (this.filterPerson.onBoarded && this.filterPerson.nonOnBoarded) {
          this.filterPerson.onBoarded = null;
          this.filterPerson.nonOnBoarded = null;
        }
        if (this.filterPerson.nonOnBoarded == null && !this.filterPerson.onBoarded) {
          this.filterPerson.onBoarded = null;
          this.filterPerson.nonOnBoarded = null;
        }
        if (this.filterPerson.nonOnBoarded && !this.filterPerson.onBoarded) {
          this.filterPerson.onBoarded = false;
        }

        this.refreshProcedure();
      }
    });
  }

  checkKeyPress($event) {
    if($event.keyCode === 13){
      this.pagination.pageNumber = 0;
      this.refreshProcedure();
    }
  }
}
