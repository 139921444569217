import {Amount} from "./person";

export class PaidUsers {
  numberOfPaidUsers: number;
  numberOfClosedUsers: number;
  numberOfBetaUsers: number;
  numberOfPausedUsers: number;
  numberOfTrialUsers: number;
  numberOfTrialOverProperties: number;
  numberOfTrialOverUsers: number;


  numberOfActivityPausedProperties: number;
  numberOfClosedProperties: number;
  numberOfBetaProperties: number;
  numberOfPaidProperties: number;
  numberOfPausedProperties: number;
  numberOfPaidPropertiesPremium: number;
  numberOfPaidPropertiesPerformance: number;
  numberOfPaidPropertiesSuccess: number;
  numberOfPaidPropertiesOthers: number;

  numberOfTrialProperties: number;

  numberOfPaidUsersPremium: number;
  numberOfPaidUsersPerformance: number;
  numberOfPaidUsersSuccess: number;

  numberOfTestProperties: number;
  numberOfTestUsers: number;

  mrr: number;
  mrrMessage: number;
  mrrPremium: number;
  mrrPerformance: number;
  mrrSuccess: number;
  mrrOthers: number;
}
